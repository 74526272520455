<template>
  <div class="picker-wrap" @click.self="close">
    <div class="pick-box">
      <div class="picker-header">
        <span class="title">{{ title }}</span>
      </div>
      <div class="picker-body" ref="wrapper">
        <ul class="picker-list">
          <li v-for="(item, index) in pickList" :key="index" :class="{ selected: index === selectIndex }" @click="() => { pickSelect(item, index) }">{{ item.text? item.text: item }}</li>
        </ul>
      </div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import BScroll from 'better-scroll';

export default {
  name: "Picker",
  props: {
    title: {
      type: String,
      required: true,
    },
    typeKey: {
      type: String,
      required: false,
      default: ''
    },
    pickList: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scroll = new BScroll(this.$refs.wrapper, { click: true })
    })
  },
  data() {
    return {
      inputVal: '',
      selectIndex: 0
    }
  },
  methods: {
    pickSelect(item, index) {
      this.selectIndex = index;
      console.log(this.selectIndex);
      this.$emit('pickerHandle', false, item, this.typeKey);
    },
    close() {
      this.$emit('pickerClose', this.typeKey);
    }
  }
};
</script>

<style>
.picker-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}
.pick-box {
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px 69px 40px;
  border-radius: 16px 16px 0px 0px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #ffffff;
}
.picker-header {
  width: 100%;
  height: 114px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #eee;
}
.title {
  font-size: 32px;
  font-weight: 500;
  color: #333;
}
.picker-body {
  border-bottom: 2px solid #eee;
  max-height: 500px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.picker-list li {
  height: 100px;
  line-height: 100px;
  text-align: center;
  color: #333;
  font-size: 30px;
}
.picker-footer {
  width: 100%;
  height: 112px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.picker-footer span {
  color: #333333;
  font-size: 28px;
}
.picker-footer input {
  border: none;
  outline: none;
  border-bottom: 2px solid #eee;
  padding-bottom: 19px;
}
.picker-footer button {
  width: 131px;
  height: 66px;
  border: none;
  outline: none;
  line-height: 66px;
  color: #ffffff;
  font-size: 28px;
  border-radius: 16px;
  background: linear-gradient(90deg, #FFC30D 0%, #FFAD00 100%, #F59A02 100%);
}
.selected {
  color: #FFC30D !important;
}
</style>
